import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"

export default function IndexPage({ data }) {
  var allTracks = data.allMarkdownRemark.edges
  allTracks.sort( function(a, b) {
    return a.node.frontmatter.order - b.node.frontmatter.order
  });
  var rb01 = allTracks.filter(tracksRb01)
  var rb03 = allTracks.filter(tracksRb03)

  function tracksRb01(track) {
    return track.node.frontmatter.edition === "I & II"
  }
  function tracksRb03(track) {
    return track.node.frontmatter.edition === "III"
  }

  return (
    <Layout>
      <div className="img-wrap">
        <Image
          relativePath="RealbookCover.png"
        />
      </div>
      <h2 style={{ textAlign: `center` }}>I & II</h2>
      <div style={{ textAlign: `center`, marginBottom: `1.45rem` }}>
        <ul className="track-list">
        {rb01.map(({ node }) => (
          <li key={node.id}>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
        </ul>
      </div>
      <h2 style={{ textAlign: `center` }}>III</h2>
      <div style={{ textAlign: `center`, marginBottom: `1.45rem` }}>
        <ul className="track-list">
        {rb03.map(({ node }) => (
          <li key={node.id}>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            edition
            order
          }
        }
      }
    }
  }
`
